import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import "./team.css"

const Team = () => {
  const data = useStaticQuery(graphql`
    fragment imageQuery on File {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      team_1: file(relativePath: { eq: "richard.jpg" }) {
        ...imageQuery
      }
      team_3: file(relativePath: { eq: "lars.jpg" }) {
        ...imageQuery
      }
    }
  `)

  return (
    <div className="white-wrapper">
      <div className="content-container">
        <h2 className="text-center">Our team</h2>
        <div className="columns">
          <div className="column">
            <div className="team-card">
              <Image fluid={data.team_1.childImageSharp.fluid} />
              <h3>Richard van den Broek</h3>
              <p>Product manager & founder</p>
              <div className="team-card-back">
                <h4>About Richard</h4>
                <p>
                  After finishing his MSc in Maritime Economics and Logistics at the Erasmus University, Richard gained hands-on experience at Stolt Tankers in
                  both operations and chartering. Subsequently he moved into web-development which allows him to bring his academic, working and coding experience
                  together in Martap.
                </p>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="team-card">
              <Image fluid={data.team_3.childImageSharp.fluid} />
              <h3>Lars Böhm</h3>
              <p>Webdevelopment & Design</p>
              <div className="team-card-back">
                <h4>About Lars</h4>
                <p>
                  Lars teaches webapplication development and design at the Le
                  Wagon international programming school. Besides that he
                  regularly works with startups to improve and develop product
                  and design.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
