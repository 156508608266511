import React from "react"
import "./problem_columns.css"

const ProblemColumns = () => {
  return (
    <div className="white-wrapper" id="problems">
      <div className="content-container">
        <div className="columns">
          <div className="column">
            <div className="text-card">
              <h3>Data</h3>
              <p>
                An immense amount of data within the bulk supply chains
                is disregarded or underutilized. Trapped in paper documents and
                email attachments, this data is fragmented and costly to validate
                and interpret. This impedes the modern potential of data-driven decision-making.
              </p>
            </div>
          </div>
          <div className="column">
            <div className="text-card">
              <h3>Laytime</h3>
              <p>
                Calculations of laytime are largely done through the experience of a
                demurrage analyst who has in-depth knowledge of the contract terms. 
                Repetitive work, especially on voyages under the same terms, occupies
                employees where there time would be better utilized in high value-adding
                tasks: actual analysis of contract risk, dispute settlement and claim
                management. 
              </p>
            </div>
          </div>
          <div className="column">
            <div className="text-card">
              <h3>Risk</h3>
              <p>
                What makes a good fixture? Too often a deal seems great at the moment
                of fixing (based on contract parameters such as freight). Months later
                it turned out a demurrage claim destroyed the P&L. Quantified analysis
                of contract risk is important to understand the granularities of risk
                exposure before fixing. Something which will enhance even the most 
                experienced broker. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProblemColumns
