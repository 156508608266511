import React from "react"
import "./contact_form.css"

const ContactForm = () => {
  return (
    <div className="white-wrapper" id="contact">
      <div className="content-container">
        <h2 className="dark-blue">Contact</h2>
        <div className="columns">
          <div className="column">
            <div className="contact-form-text dark-blue">
              <p>
                Want to find out more about our projects, or do you have a
                question? Don’t hesitate to send us a message at
                info@martap-solutions.com or by filling out the contact form.
              </p>
            </div>
          </div>
          <div className="column">
            <div className="contact-form">
              <form
                action="https://formspree.io/info@martap-solutions.com"
                method="POST"
              >
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" placeholder="John" />
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="_replyto"
                  id="email"
                  placeholder="yourname@yourdomain.com"
                />
                <label htmlFor="message">Message</label>
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  placeholder="Hi, I'd like to contact you about..."
                ></textarea>
                <input
                  type="submit"
                  value="Submit"
                  className="btn-martap dark-blue"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
