import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "./hero.css"

class Hero extends Component {
  scrollToTarget(event) {
    document
      .querySelector(event.currentTarget.dataset.target)
      .scrollIntoView({ behavior: "smooth", position: "top" })
  }

  render() {
    return (
      <BackgroundImage
        Tag="section"
        className={"image-wrapper hero"}
        fluid={this.props.result.childImageSharp.fluid}
        backgroundColor={`var(--light-blue)`}
      >
        <div className="content-container">
          <h1>Unlocking data in bulk shipping</h1>
          <p>Enhance workflow and business intelligence by digitizing your paper trail</p>
          <button
            className="btn-martap red"
            onClick={this.scrollToTarget}
            data-target="#problems"
          >
            Read more &rarr;
          </button>
        </div>
      </BackgroundImage>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        result: file(relativePath: { eq: "background.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ result }) => <Hero result={result} {...props} />}
  />
)
