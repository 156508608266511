import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import "./text_with_image.css"

const TextWithImage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "binocular.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="dark-blue-wrapper image-parent" id="about">
      <div className="content-container">
        <div className="columns">
          <div className="column">
            <div className="text-card-white">
              <h2>About us</h2>
              <p>
                Our mission is to help companies involved in the maritime bulk supply
                chain steer into the data-driven era of business. A tremendous untapped
                potential for enhanced workflow and better-informed decisions exists
                in dormant data; trapped in improper extraction, exchange, storage
                and interpretation.
                <br />
                <br />
                We commit to unlock that potential.
              </p>
            </div>
          </div>
          <div className="column">
            <div className="image-card-high">
              <Image fluid={data.file.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextWithImage
