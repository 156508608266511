import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/index"
import ProblemColumns from "../components/text/problem_columns/index"
import TextWithImage from "../components/text/text_with_image/index"
import FeatureColumns from "../components/text/feature_columns/index"
import Team from "../components/team/index"
import ContactForm from "../components/contact_form/index"

const IndexPage = props => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <ProblemColumns />
    <FeatureColumns />
    <TextWithImage />
    <Team />
    <ContactForm />
  </Layout>
)

export default IndexPage
